import React, { useContext } from "react"
import FormRadio from "../Elements/Form/FormRadio"
import { Formik, Form } from "formik"
import Section from "../Elements/Section"
import Container from "../Layout/Container"
import { AppContext } from "../../context/AppContext"
import ActionButtons from "../Elements/ActionButtons"
import { navigate } from "gatsby"

const BenefitType = ({ nextRoute, backRoute, location }) => {
  const { state, dispatch } = useContext(AppContext)
  const handleOnSubmit = async values => {
    await dispatch({
      type: "SAVE_BENEFIT_TYPE",
      payload: values.benefitType,
    })
    let rxRequired = ["Optical", "Medical"].includes(values.benefitType)
    await dispatch({
      type: "SAVE_RX_REQUIRED",
      payload: rxRequired,
    })
    if (!rxRequired) {
      dispatch({
        type: "SAVE_DOCUMENTS",
        payload: [],
      })
    }
    nextRoute = rxRequired ? nextRoute : "/flexmed/encode"
    let prevPath = location.pathname
    navigate(nextRoute, { state: { prevPath } })
  }

  return (
    <Container isCentered desktop={6}>
      <div className="content">
        <Formik
          initialValues={{
            benefitType: state.flexmed.benefitType,
          }}
          onSubmit={handleOnSubmit}
        >
          {({ values }) => (
            <Form>
              <Section title="What type of benefit are you filling for?">
                <FormRadio
                  name="benefitType"
                  value={values.benefitType}
                  options={["Optical", "Medical", "Dental", "Wellness"]}
                  isInline
                />
              </Section>

              <ActionButtons
                back={{
                  label: "Back",
                  link: location?.state?.prevPath || backRoute,
                }}
                submit={{
                  label: "Next",
                  disabled: !values.benefitType.length,
                }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  )
}

export default BenefitType
