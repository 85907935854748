import React from "react"
import Layout from "../../components/Layout/Layout"
import BenefitType from "../../components/Mechanics/BenefitType"

export default ({ location }) => {
  return (
    <Layout
      title="Benefit Type"
      flow="flexmed"
      seoTitle="Flexmed Benefit Type"
      process="flexmed"
    >
      <BenefitType
        nextRoute="/flexmed/upload"
        backRoute="/flexmed/mechanics"
        location={location}
      />
    </Layout>
  )
}
