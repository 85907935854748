import React, { useRef, useEffect } from "react"
import { Field, ErrorMessage, useFormikContext } from "formik"
import classNames from "classnames"
import styles from "../utils/elements.module.scss"

const FormRadio = ({
  name,
  options,
  value,
  onChange,
  title,
  isInline,
  className,
  isRequired,
  hideOptional,
}) => {
  const formikContext = useFormikContext()

  const fieldRef = useRef(null)

  const handleScrollCallback = () => {
    fieldRef.current.scrollIntoView({ scroll: "smooth", block: "center" })
  }

  useEffect(() => {
    let { errors } = formikContext
    errors = Object.keys(errors)
    if (errors.length && errors[0] === name) {
      handleScrollCallback()
    }
  }, [formikContext])

  const handleChange = (form, option) => event => {
    const { setFieldValue } = form
    if (event.target.checked) setFieldValue(name, event.target.value)
    if (onChange) onChange(event)
  }

  const RadioButton = ({ form, option, index }) => (
    <div
      className={classNames("column", {
        [styles["formRadio__isActive"]]: value === option,
      })}
      id={`option${index}`}
      onClick={() =>
        handleChange(form, option)({ target: { checked: true, value: option } })
      }
    >
      <span>
        <input
          className="radio"
          id={`option${index + 1}RadioButton`}
          type="radio"
          name={name}
          value={option}
          onChange={handleChange(form, option)}
          checked={option === value}
        />
        <label
          className={classNames("mx-1")}
          for={`option${index + 1}RadioButton`}
        >
          {option}
        </label>
      </span>
    </div>
  )

  return (
    <div ref={fieldRef}>
      <Field name={name}>
        {({ form }) => (
          <div>
            {title && (
              <label
                className={classNames(`label has-text-weight-normal mr-1`, {
                  "form__radioLabel--displayInline": isInline,
                })}
              >
                {title}{" "}
                {!isRequired && !hideOptional && (
                  <span className="has-text-grey is-italic">(Optional)</span>
                )}
              </label>
            )}
            <div className={className ? className.radioGroup : ""}>
              {options.map((option, index) => (
                <RadioButton form={form} option={option} index={index} />
              ))}
            </div>
          </div>
        )}
      </Field>
      <p className="help is-danger mt-0 mb-1">
        <ErrorMessage name={name} />
      </p>
    </div>
  )
}

export default FormRadio
